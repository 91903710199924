import React from 'react';

import IcoFacebook from '../../../static/images/icons/facebook.svg';
import IcoInstagram from '../../../static/images/icons/instagram.svg';

const InformationEn = () => {
  return (
    <div className="information-content">
      <h1>Contact us</h1>

      <div className="block">
        <p>
          <strong>Email:</strong>
        </p>

        <p>entestudio1@gmail.com</p>
      </div>

      <div className="block">
        <p>
          <strong>Phone:</strong>
        </p>

        <p>(+51) 943 814 259</p>
      </div>

      <div className="block">
        <p>
          <strong>Address:</strong>
        </p>

        <p>
          Juan de la Fuente 185 <br />
          Miraflores <br />
          Lima, Peru
        </p>
      </div>

      <ul className="redes">
        <li>
          <a href="https://facebook.com/entesdmjc/" target="_blank" rel="noreferrer noopener nofollow">
            <img src={IcoFacebook} alt="Facebook" />
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/entesdmjc/" target="_blank" rel="noreferrer noopener nofollow">
            <img src={IcoInstagram} alt="Instagram" />
          </a>
        </li>
      </ul>
      
    </div>
  );
};

export default InformationEn;
