import React from 'react';

import Layout from '../layouts/en.jsx';

import InformationEn from '@components/pages/contact/Information.en';
import FormEn from '@components/pages/contact/Form.en';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const ContactPage = () => {
  const {wpPage}  = useStaticQuery(graphql`
      query ContactUsEnglish {
        wpPage(slug: {eq: "contact-us"}) {
          id
          date
          title
          slug
          status
          featuredImage {
            node {
              sourceUrl
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          content
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
            }
            twitterTitle
          }
        }
      }
      `);
  return (
    <>
      <Seo seo={wpPage.seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/en/contact-us"/>
      <Layout pageId="contact" scrollTop={false}>
        <div className="container">
          <div className="content">
            <div className="information">
              <InformationEn />
            </div>
            <FormEn />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;
