import React from 'react';
import PropTypes from 'prop-types';

import { NavBarEn, Footer } from '@includes';

const Layout = ({ children, pageId, scrollTop }) => {
  return (
    <div id="wrapper">
      <NavBarEn />
      <div id="main-content">
        <div className="main-container">
          <main id={pageId}>
            {children}
          </main>
          <Footer scrollTop={scrollTop} />
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageId: PropTypes.string,
  scrollTop: PropTypes.bool,
};

Layout.defaultProps = {
  scrollTop: true,
};

export default Layout;
